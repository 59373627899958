import React from "react"

const Glassdoor = ({ pagedata }) => {
  const sectionTitle =
    pagedata.cfCareersGlassdoor.staffSaySectionTitle || "What Our Staff Say"
  const sectionIntro =
    pagedata.cfCareersGlassdoor.staffSayIntroParagraph ||
    "Our community work hard and play hard. We offer a diverse and inclusive environment for everyone to learn, grow and have fun."

  return (
    <div className="container mb-6">
      <div className="row">
        <div className="col-12 col-lg-4 mb-3 mb-lg-0">
          <h3 className="lower">{sectionTitle}</h3>
        </div>
        <div className="col-12 col-lg-8">
          <p
            className="mb-5"
            dangerouslySetInnerHTML={{ __html: sectionIntro }}
          />
          {pagedata.cfCareersGlassdoor.staffSayGlassdoorComments.map(
            (comment, index) => (
              <div
                key={index}
                className="d-flex flex-column flex-md-row border-top pt-4 mt-3"
              >
                <div className="flex-shrink-0 mb-3 mb-md-0">
                  <img
                    className="mw-100"
                    src="/assets/img/glassdoor-five-star.png"
                    alt="glassdoor five star"
                    width="118px"
                  />
                </div>
                <h3 className="h4 w-100 ps-0 ps-md-3 pe-0 pe-md-3 mb-3 mb-md-0">
                  {comment.staffSayGdComment}
                </h3>
                <p className="flex-shrink-0">{comment.staffSayGdDate}</p>
              </div>
            )
          )}

          <div className="d-flex border-top pt-4 mt-3">
            <div className="col-6">
              <a
                href="https://www.glassdoor.co.uk/Overview/Working-at-Waracle-EI_IE921790.11,18.htm"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="glassdoor logo"
                  src="https://www.glassdoor.co.uk/api/widget/verticalStarRating.htm?e=921790"
                  style={{
                    width: "155px",
                    border: "none",
                  }}
                />
              </a>
            </div>
            <div className="col-6 d-none d-sm-flex justify-content-end align-items-center">
              <a
                className="ani_grey_arrow right_aligned"
                href="https://www.glassdoor.co.uk/Overview/Working-at-Waracle-EI_IE921790.11,18.htm"
                target="_blank"
                rel="noreferrer"
                title="All Glassdoor Reviews"
              >
                All Glassdoor Reviews
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Glassdoor
