import React from "react"
import { Accordion, useAccordionButton } from "react-bootstrap"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { fill } from "@cloudinary/url-gen/actions/resize"
import * as style from "./careers-benefits.module.css"

const CareersBenefits = ({ pagedata }) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => console.log(""))

    return (
      <button
        type="button"
        onClick={decoratedOnClick}
        className="nav-btn text-start d-flex align-items-start"
      >
        <img
          className="plus-icon add_transition"
          src="/assets/img/close-icon.svg"
          alt=""
        />
        {children}
      </button>
    )
  }

  // re-use of previous LHS/THS requires array merge and default value on null within accordian mapping

  const fullBenefitArray = [
    ...pagedata.bensLhsBenefits,
    ...pagedata.bensRhsBenefits,
  ]

  return (
    <>
      <div className="container mb-6">
        <div className="row">
          <div className="col-12 col-lg-4 mb-3 mb-lg-0 pe-0 pe-lg-5">
            <h3 className="lower">{pagedata.bensTitle}</h3>
          </div>
          <div className="col-12 col-lg-8">
            <p
              dangerouslySetInnerHTML={{ __html: pagedata.bensIntroParagraph }}
            />
            <div className="row">
              <Accordion className="accordion border-top mt-5">
                {fullBenefitArray.map((aBen, index) => {
                  let slug = ""
                  let altText = ""
                  if (aBen.bensLhsIcon) {
                    slug = aBen.bensLhsIcon.mediaDetails.file
                    altText = aBen.bensLhsIcon.altText
                  } else {
                    slug = aBen.bensRhsIcon.mediaDetails.file
                    altText = aBen.bensRhsIcon.altText
                  }

                  const bensDetail =
                    aBen.bensLhsBenefitDetail || aBen.bensRhsBenefitDetail

                  let theImage = cld.image(
                    `${process.env.GATSBY_API_FOLDER_URL}${slug}`
                  )
                  theImage.resize(fill().width(100).height(100))
                  theImage.format("auto")

                  return (
                    <div key={index} className="border-bottom py-3">
                      <CustomToggle eventKey={index}>
                        <span className={style.icon_clearance}>
                          <AdvancedImage
                            cldImg={theImage}
                            alt={`${altText} icon`}
                            className="inline-icon"
                          />
                        </span>
                        {aBen.bensLhsBenefitName || aBen.bensRhsBenefitName}
                      </CustomToggle>
                      <Accordion.Collapse eventKey={index}>
                        <div className="accordion-collapse">
                          <p
                            className="pt-3 mb-0 ps-md-4 ms-md-2"
                            dangerouslySetInnerHTML={{ __html: bensDetail }}
                          />
                        </div>
                      </Accordion.Collapse>
                    </div>
                  )
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CareersBenefits
