import React from "react"
import { Accordion, useAccordionButton } from "react-bootstrap"

const CareerFaqs = ({ pagedata }) => {
  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => console.log(""))

    return (
      <button
        type="button"
        onClick={decoratedOnClick}
        className="nav-btn text-start d-flex align-items-start"
      >
        <img
          className="plus-icon add_transition"
          src="/assets/img/close-icon.svg"
          alt=""
        />
        {children}
      </button>
    )
  }

  return (
    <div className="container mb-6">
      <div className="row">
        <div className="col-12 col-lg-4 mb-3 mb-lg-0 pe-0 pe-lg-5">
          <h3 className="lower">{pagedata.faqsSectionTitle}</h3>
        </div>
        <div className="col-12 col-lg-8">
          <p
            dangerouslySetInnerHTML={{ __html: pagedata.faqsIntroParagraph }}
          />
          <Accordion className="accordion border-top mt-5">
            {pagedata.faqsFaqs.map((faq, index) => (
              <div key={index} className="border-bottom py-3">
                <CustomToggle eventKey={index}>
                  {faq.faqsFaqQuestion}
                </CustomToggle>
                <Accordion.Collapse eventKey={index}>
                  <div className="accordion-collapse">
                    <p className="pt-3 mb-0 ps-md-4 ms-md-2">
                      {faq.faqsFaqAnswer}
                    </p>
                  </div>
                </Accordion.Collapse>
              </div>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  )
}

export default CareerFaqs
