import React from "react"
import { Link } from "gatsby"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { fill } from "@cloudinary/url-gen/actions/resize"

const StaffStories = ({ pagedata }) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  // image 1
  let slug_1 = pagedata.cpWdaImg1.mediaDetails.file
  let altText_1 = pagedata.cpWdaImg1.altText

  let theImage_1 = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${slug_1}`)

  theImage_1.resize(fill().width(516).height(377))
  theImage_1.format("auto")

  // image 2
  let slug_2 = pagedata.cpWdaImg2.mediaDetails.file
  let altText_2 = pagedata.cpWdaImg2.altText

  let theImage_2 = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${slug_2}`)

  theImage_2.resize(fill().width(516).height(470))
  theImage_2.format("auto")

  return (
    <div className="grey_bg color-white mb-6 pt-6 pb-6">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4 mb-3 mb-lg-0">
            <h3 className="lower">{pagedata.cpWdaLhsTitle}</h3>
          </div>
          <div className="col-12 col-lg-8">
            <div className="row mb-2 mb-lg-5">
              <div className="col-12 col-md-6">
                <AdvancedImage
                  cldImg={theImage_1}
                  alt={altText_1}
                  className="mw-100 mb-3 mb-md-4 mb-lg-0"
                />
              </div>
              <div className="col-12 col-md-6">
                <AdvancedImage
                  cldImg={theImage_2}
                  alt={altText_2}
                  className="mw-100 mb-3 mb-md-4 mb-lg-0"
                />
              </div>
            </div>
            <p
              className="mb-4"
              dangerouslySetInnerHTML={{ __html: pagedata.cpWdaIntroPara }}
            />
            <Link
              to="/waracle-digital-academy/"
              title="Digital Academy Shcedule"
              className="ani_white_arrow"
            >
              Digital Academy Schedule
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StaffStories
