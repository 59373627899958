import React from "react"
import { graphql } from "gatsby"
import Layout from "./Layout"
import CareersBenefits from "../components/careers/careers-benefits"
import CareersHero from "../components/careers/hero"
import LifeAtWaracle from "../components/careers/life-at-waracle"
import StaffStories from "../components/careers/careers-stories-wda"
import Glassdoor from "../components/careers/glassdoor"
import CareerFaqs from "../components/careers/career-faqs"
import CareerCtas from "../components/careers/career-ctas"
import { seoHead } from "../components/utils.js"

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id) {
        id
        title
        content
        uri
        cfCareersParent {
          cpHeroImg1 {
            altText
            mediaDetails {
              file
            }
          }
          cpHeroImg2 {
            altText
            mediaDetails {
              file
            }
          }
          cpHeroImg3 {
            altText
            mediaDetails {
              file
            }
          }
          cpHeroSubtitle
          cpHeroSubtitleLine2
          cpHeroTitle
          wdaWhyWdaTitle
          whyWdaMainContent
          cpLifeSectionImage {
            altText
            mediaDetails {
              file
            }
          }
          cpWdaImg1 {
            altText
            mediaDetails {
              file
            }
          }
          cpWdaImg2 {
            altText
            mediaDetails {
              file
            }
          }
          cpWdaIntroPara
          cpWdaLhsTitle
        }

        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }

        cfCareersGlassdoor {
          staffSayIntroParagraph
          staffSaySectionTitle
          staffSayGlassdoorComments {
            staffSayGdComment
            staffSayGdDate
          }
        }
      }

      acfOptionsCareers {
        cfCareersSingleVac {
          singleVacHeroImage1 {
            altText
            mediaDetails {
              file
            }
          }
        }
        cfBenefits {
          bensIntroParagraph
          bensTitle
          bensLhsBenefits {
            bensLhsBenefitName
            bensLhsIcon {
              altText
              mediaDetails {
                file
              }
            }
            bensLhsBenefitDetail
          }
          bensRhsBenefits {
            bensRhsBenefitName
            bensRhsIcon {
              altText
              mediaDetails {
                file
              }
            }
            bensRhsBenefitDetail
          }
        }
        cfCareersFaqs {
          faqsIntroParagraph
          faqsSectionTitle
          faqsFaqs {
            faqsFaqAnswer
            faqsFaqQuestion
          }
        }
      }
    }
  }
`
const PageTemplateCareersParent = ({ data }) => {
  const page = data.wpgraphql.page
  const benefits = data.wpgraphql.acfOptionsCareers.cfBenefits
  const faqs = data.wpgraphql.acfOptionsCareers.cfCareersFaqs
  const previewImg =
    data.wpgraphql.acfOptionsCareers.cfCareersSingleVac.singleVacHeroImage1
      .mediaDetails.file

  return (
    <>
      <Layout>
        <CareersHero pagedata={page.cfCareersParent} />

        <LifeAtWaracle pagedata={page.cfCareersParent} />

        <CareersBenefits pagedata={benefits} />

        <StaffStories pagedata={page.cfCareersParent} />

        <Glassdoor pagedata={page} />

        <CareerFaqs pagedata={faqs} />

        <CareerCtas previewImg={previewImg} />
      </Layout>
    </>
  )
}

export default PageTemplateCareersParent

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const page = data.wpgraphql.page
  const seoTags = seoHead(page.seo, page.uri)
  return seoTags
}
