import React from "react"
import { Link } from "gatsby"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { fill } from "@cloudinary/url-gen/actions/resize"
import { isSafariLess16 } from "../utils.js"

const CareersHero = ({ pagedata }) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  // hero image 1
  let slug_1 = pagedata.cpHeroImg1.mediaDetails.file
  let altText_1 = pagedata.cpHeroImg1.altText

  let theImage_1 = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${slug_1}`)

  theImage_1.resize(fill().width(448).height(620))
  theImage_1.format("auto")

  // hero image 2
  let slug_2 = pagedata.cpHeroImg2.mediaDetails.file
  let altText_2 = pagedata.cpHeroImg2.altText

  let theImage_2 = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${slug_2}`)

  theImage_2.resize(fill().width(379).height(557))
  theImage_2.format("auto")

  // hero image 3
  let slug_3 = pagedata.cpHeroImg3.mediaDetails.file
  let altText_3 = pagedata.cpHeroImg3.altText

  let theImage_3 = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${slug_3}`)

  theImage_3.resize(fill().width(616).height(856))
  theImage_3.format("auto")

  const isSafariV16 = isSafariLess16()

  return (
    <>
      <div className="hero-md d-flex flex-column flex-lg-row align-items-center darkgrey_bg mb-6 position-relative">
        <div className="container">
          <div className="row">
            <div
              id="hide-row-when-nav-open"
              className="col-12 col-lg-7 d-flex align-items-center color-white animated-headline mt-5 mt-lg-0"
            >
              <div className="col-12 pe-0 pe-lg-4 mb-5 mb-lg-0">
                <span className="header-fade-in d-block h4 mb-3 text-uppercase">
                  {pagedata.cpHeroTitle}
                </span>
                <div className="animated-headline-wrap">
                  <h1 className="h2 new_style">
                    <span
                      className={`new_style_addit ${
                        isSafariV16 ? "safari_16" : ""
                      }`}
                    >
                      {pagedata.cpHeroSubtitle}{" "}
                      <br className="d-none d-sm-block" />
                      {pagedata.cpHeroSubtitleLine2}
                    </span>
                  </h1>
                </div>
                <div className="d-flex flex-wrap mt-4 header-fade-in">
                  <Link
                    to="/careers/open-roles/"
                    className=" col-12 col-md-4 py-2 px-1 mt-2 mt-md-0 light_bg btn search"
                    title="Search"
                  >
                    <img
                      className="inline-icon"
                      src="/assets/img/icon_search.svg"
                      style={{ height: "20px" }}
                      alt="search icon"
                    />
                    View open roles
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-5 d-flex align-items-center header-fade-in">
              <div className="col-8 pe-3">
                <AdvancedImage
                  cldImg={theImage_1}
                  alt={altText_1}
                  className={`w-100`}
                />
              </div>
              <div className="col-4 col-lg-5 d-flex flex-column">
                <AdvancedImage
                  cldImg={theImage_2}
                  alt={altText_2}
                  className={`w-100 pb-3`}
                />
                <AdvancedImage
                  cldImg={theImage_3}
                  alt={altText_3}
                  className={`w-100 pe-3`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CareersHero
