import React from "react"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { fill } from "@cloudinary/url-gen/actions/resize"

const LifeAtWaracle = ({ pagedata }) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  // hero image 1
  let slug_1 = pagedata.cpLifeSectionImage.mediaDetails.file
  let altText_1 = pagedata.cpLifeSectionImage.altText

  let theImage_1 = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${slug_1}`)
  theImage_1.resize(fill().width(856).height(571))
  theImage_1.format("auto")

  return (
    <div className="container mb-6">
      <div className="row">
        <div className="col-12 col-lg-4 mb-3 mb-lg-0">
          <h3 className="lower">{pagedata.wdaWhyWdaTitle}</h3>
        </div>
        <div className="col-12 col-lg-8">
          <AdvancedImage
            cldImg={theImage_1}
            alt={altText_1}
            className={`w-100 mb-5`}
          />
          <p dangerouslySetInnerHTML={{ __html: pagedata.whyWdaMainContent }} />
        </div>
      </div>
    </div>
  )
}

export default LifeAtWaracle
